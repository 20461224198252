<template>
    <div class="flex h-full w-full flex-col items-center justify-center">
        <img
            :src="$filters.asset('static/images/illustration/card.png')"
            class="mb-3"
            :alt="bankName"
        />
        <div class="mb-8 max-w-sm text-center">
            <h4 class="mb-2 text-lg font-medium text-gray-900">
                {{
                    $t(
                        'settingsAccounting.paymentAccount.integrate.connectSuccessHeading',
                        { name: bankName }
                    )
                }}
            </h4>
            <p class="text-sm font-normal text-gray-500">
                {{
                    $t(
                        'settingsAccounting.paymentAccount.integrate.connectSuccessDescription'
                    )
                }}
            </p>
        </div>
        <div class="flex gap-x-3">
            <base-button
                outline
                variant="default"
                @click="redirectToConnectBank"
            >
                {{
                    $t(
                        'settingsAccounting.paymentAccount.integrate.connectOtherButton'
                    )
                }}
            </base-button>
            <base-button
                variant="primary"
                icon="line-icons:arrows:arrow-circle-right"
                @click="redirectToAccount"
            >
                {{
                    $t(
                        'settingsAccounting.paymentAccount.integrate.goToAccountButton'
                    )
                }}
            </base-button>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
    paymentAccount: {
        type: Object,
        required: true,
    },
})

const router = useRouter()

const bankName = computed(
    () => props.paymentAccount.payment_connector?.provider_name
)

const redirectToConnectBank = () => {
    router.push({
        name: 'settings.accounting.payment-account.connect-bank',
    })
}

const redirectToAccount = () => {
    router.push({
        name: 'bookeeping.bank.detail',
        params: {
            id: `${props.paymentAccount.id}`,
        },
    })
}
</script>
