<template>
    <base-modal
        :show="show"
        size="lg"
        close
        custom-content-class="pt-1"
        @modal-close="closeModal"
    >
        <template #modal-header>
            <h5 class="text-lg font-medium text-gray-900">
                {{ $t('settingsAccounting.paymentAccount.addModal.title') }}
            </h5>
        </template>
        <div class="flex flex-col gap-1 text-sm font-normal text-gray-500">
            <span>
                {{
                    $t('settingsAccounting.paymentAccount.addModal.youMayWant')
                }}
            </span>
            <ul class="list-disc pl-6">
                <li v-for="i in 3" :key="i">
                    {{
                        $t(`settingsAccounting.paymentAccount.addModal.li${i}`)
                    }}
                </li>
            </ul>
            <span>
                {{
                    $t(
                        'settingsAccounting.paymentAccount.addModal.transactionDownload'
                    )
                }}
            </span>
        </div>

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.close') }}
                </base-button>
                <base-button full-width variant="primary" @click="onContinue">
                    {{ $t('general.continue') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'on-continue'])

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
})

const closeModal = () => {
    emit('modal-close')
}

const onContinue = () => {
    emit('on-continue')
}
</script>
