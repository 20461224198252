<template>
    <payment-account-bank-connect-success
        v-if="paymentAccount"
        :payment-account="paymentAccount"
    />

    <payment-account-integrate-account-modal
        :show="!paymentAccount"
        @on-success="onSuccess"
    />
</template>

<script setup>
const { t } = useI18n()

const paymentAccount = ref(null)

useHead({
    title: t('settingsAccounting.paymentAccount.integrate.title'),
})

const onSuccess = (value) => {
    paymentAccount.value = value
}
</script>
