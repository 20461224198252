<template>
    <base-sticky-heading content-class="bg-gray-50 p-6">
        <template #title>
            <strong class="py-2 text-xl font-medium text-gray-900">
                {{ $t('settingsAccounting.paymentAccount.connectBank.title') }}
            </strong>
        </template>

        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button variant="default" @click="onAddBankFeed">
                    {{
                        $t(
                            'settingsAccounting.paymentAccount.connectBank.addWithoutBankFeed'
                        )
                    }}
                </base-button>
            </div>
        </template>
        <div class="w-full">
            <div
                class="mx-auto flex max-w-4xl flex-col rounded-md border border-gray-300 bg-white"
            >
                <div class="border-b border-gray-200 p-6 text-center">
                    <div class="mb-7 flex flex-col gap-1 text-center">
                        <h5 class="text-lg font-medium text-gray-900">
                            {{
                                $t(
                                    'settingsAccounting.paymentAccount.connectBank.findAccount'
                                )
                            }}
                        </h5>
                        <span class="text-sm font-normal text-gray-500">
                            {{
                                $t(
                                    'settingsAccounting.paymentAccount.connectBank.findDescription'
                                )
                            }}
                        </span>
                    </div>
                    <form-text-input
                        :placeholder="
                            $t(
                                'settingsAccounting.paymentAccount.connectBank.searchText'
                            )
                        "
                        @keydown="onSearch"
                    />
                </div>

                <div class="flex flex-col gap-3.5 p-6">
                    <div
                        v-if="state.searching"
                        class="flex flex-col items-center gap-4"
                    >
                        <base-loading />
                        <span class="text-sm font-medium text-gray-700">
                            {{
                                $t(
                                    'settingsAccounting.paymentAccount.connectBank.searching'
                                )
                            }}
                        </span>
                    </div>

                    <template v-else>
                        <template v-if="banks && banks.length > 0">
                            <form-radio-group-item
                                name="bank"
                                v-for="bank in banks"
                                :value="bank"
                                v-model="bankModel"
                                :key="bank.id"
                            >
                                <template #default="{ checked }">
                                    <img
                                        v-if="isEmpty(bank.logo_url)"
                                        :src="
                                            $filters.asset(
                                                'static/images/bank-fallback.png'
                                            )
                                        "
                                        :alt="bank.name"
                                        class="h-10 w-10 rounded-full"
                                    />
                                    <object
                                        v-else
                                        :data="bank.logo_url"
                                        type="image/png"
                                        class="h-10 w-10 rounded-full"
                                    >
                                        <img
                                            :src="
                                                $filters.asset(
                                                    'static/images/bank-fallback.png'
                                                )
                                            "
                                        />
                                    </object>

                                    <div class="ml-3.5">
                                        <p
                                            class="block text-sm font-medium text-gray-700"
                                        >
                                            {{ bank.name }}
                                        </p>
                                        <p
                                            v-if="bank.bic"
                                            class="block text-sm font-normal"
                                            :class="
                                                checked
                                                    ? 'text-primary-600'
                                                    : 'text-gray-500'
                                            "
                                        >
                                            {{
                                                $t(
                                                    'settingsAccounting.paymentAccount.connectBank.bankDescription',
                                                    { bic: bank.bic }
                                                )
                                            }}
                                        </p>
                                    </div>
                                </template>
                            </form-radio-group-item>
                        </template>

                        <div
                            v-else
                            class="flex flex-col items-center px-32 text-center"
                            @click="onAddBankFeed"
                        >
                            <base-feature-icon
                                class="mb-4"
                                size="lg"
                                variant="primary"
                                name="line-icons:general:search-lg"
                                type="highlight"
                            />
                            <span
                                class="mb-1 text-md font-medium text-gray-900"
                            >
                                {{
                                    $t(
                                        'settingsAccounting.paymentAccount.connectBank.noResultsMatch'
                                    )
                                }}
                            </span>
                            <span class="text-sm font-normal text-gray-500">
                                {{
                                    $t(
                                        'settingsAccounting.paymentAccount.connectBank.tryCheckingYourSpelling'
                                    )
                                }}
                            </span>
                            <base-button class="mt-10" variant="default">
                                {{
                                    $t(
                                        'settingsAccounting.paymentAccount.connectBank.addWithoutBankFeed'
                                    )
                                }}
                            </base-button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </base-sticky-heading>

    <payment-account-connect-bank-verify-modal
        @modal-close="onCloseVerifyModal"
        @on-submit="onSubmitConnectBank"
        :show="showVerifyModal"
    />

    <payment-account-add-modal
        :show="showContinueModal"
        @modal-close="onCloseAddModal"
        @on-continue="onContinue"
    />
</template>

<script setup>
import { isEmpty } from 'lodash-es'
import { useDebounceFn } from '@vueuse/core'
import { delay } from '@tenant/utils/helper'

const emitter = useEmitter()
const router = useRouter()
const { t } = useI18n()
const { errorNotify } = useNotification()

const { value: bankModel } = useField('bank')
const { execute: executeGetBank } = useApi('/api/banks', 'GET')
const { execute: executeConnectBank } = useApi(
    '/api/payment-accounts/provider-bank/redirect',
    'POST'
)

useHead({ title: t('settingsAccounting.paymentAccount.connectBank.title') })

const showVerifyModal = ref(false)
const showContinueModal = ref(false)
const banks = ref([])

const state = reactive({
    searchedText: null,
    searching: false,
})

onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        banks.value = await executeGetBank()
    } finally {
        emitter.emit('set-loading', false)
    }
})

const onCancel = () => {
    router.push({
        name: 'bookeeping.bank.list',
    })
}

const onAddBankFeed = () => {
    showContinueModal.value = true
}

const onContinue = () => {
    router.push({
        name: 'settings.accounting.payment-account.add',
    })
}

watch(
    () => bankModel.value,
    (value) => {
        if (!value) {
            return
        }

        showVerifyModal.value = true
    }
)

const onCloseVerifyModal = () => {
    bankModel.value = null
    showVerifyModal.value = false
}

const onCloseAddModal = () => {
    showContinueModal.value = false
}

const onSearch = useDebounceFn(async (e) => {
    const searchText = e.target.value
    if (searchText && searchText.length < 2) {
        return
    }

    if (state.searchedText === searchText) {
        return
    }

    state.searchedText = searchText

    try {
        state.searching = true

        banks.value = await executeGetBank({
            queries: {
                q: searchText,
            },
        })

        if (searchText) {
            await delay(1500)
        }
    } finally {
        state.searching = false
    }
}, 300)

const onSubmitConnectBank = async () => {
    try {
        const bankData = bankModel.value
        onCloseVerifyModal()

        emitter.emit('set-loading', true)
        window.location.href = await executeConnectBank({
            data: {
                provider_name: bankData.name,
                provider_logo: bankData.logo_url,
                provider_id: bankData.id,
            },
        })
    } catch {
        errorNotify({
            text: t(
                'settingsAccounting.paymentAccount.connectBank.cannotConnectMsg'
            ),
        })
        emitter.emit('set-loading', false)
    }
}
</script>
