<template>
    <payment-account-bank-connect-success
        v-if="paymentAccount"
        :payment-account="paymentAccount"
    />

    <base-modal
        :show="!paymentAccount"
        :show-header="false"
        :show-footer="false"
        size="sm"
        @modal-close="onClose"
    >
        <div
            class="flex flex-col items-center justify-center pb-24 pt-20 text-center"
        >
            <base-loading class="mb-4" size="xl" />

            <p class="mb-2.5 text-lg font-medium text-gray-700">
                {{
                    $t(
                        'settingsAccounting.paymentAccount.integrate.connectingYourBank'
                    )
                }}
            </p>
            <p class="mb-8 text-sm font-medium text-gray-500">
                {{
                    $t(
                        'settingsAccounting.paymentAccount.integrate.thisCanTake'
                    )
                }}
            </p>
            <base-button variant="primary" size="sm" @click="onClose">
                {{
                    $t(
                        'settingsAccounting.paymentAccount.integrate.closeAndGoToBack'
                    )
                }}
            </base-button>
        </div>
    </base-modal>
</template>

<script setup>
import { ref } from 'vue'

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const { errorNotify } = useNotification()

const paymentAccount = ref(null)

const { execute } = useApi(
    '/api/payment-accounts/bank/accounts/reconnect',
    'POST'
)

onMounted(() => {
    reconnect()
})

const onClose = () => {
    router.push({
        name: 'bookeeping.bank.list',
    })
}

const reconnect = async () => {
    try {
        const { code, ref, ...queries } = route.query

        paymentAccount.value = await execute({
            data: {
                ...queries,
                code: code || ref,
            },
        })
    } catch {
        errorNotify({
            title: t('settingsAccounting.paymentAccount.message.codeExpired'),
        })

        await router.push({
            name: 'settings.accounting.payment-account.connect-bank',
        })
    }
}
</script>
