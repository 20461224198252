import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'settings.accounting',
        path: '/settings/accounting',
        children: [
            {
                name: 'settings.accounting.payment-account.add',
                path: 'payment-account/add',
                component: () => import('./pages/payment-account-add.vue'),
            },
            {
                name: 'settings.accounting.payment-account.connect-bank',
                path: 'payment-account/connect-bank',
                component: () =>
                    import('./pages/payment-account-connect-bank.vue'),
            },
            {
                name: 'settings.accounting.payment-account.integrate.account',
                path: 'payment-account/integrate/account',
                component: () =>
                    import('./pages/payment-account-integrate-account.vue'),
            },
            {
                name: 'settings.accounting.payment-account.reconnect.account',
                path: 'payment-account/reconnect/account',
                component: () =>
                    import('./pages/payment-account-reconnect-account.vue'),
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'create_bank_accounts',
    },
}
