<template>
    <base-sticky-heading content-class="bg-gray-50 p-6">
        <template #title>
            <strong class="py-2 text-xl font-medium text-gray-900">
                {{ $t('settingsAccounting.paymentAccount.add.title') }}
            </strong>
        </template>

        <div class="w-full">
            <form
                class="mx-auto flex max-w-4xl flex-col rounded-md border border-gray-300 bg-white"
                @submit="submitForm"
            >
                <div class="border-b border-gray-200 p-6">
                    <h5 class="text-lg font-medium text-gray-900">
                        {{
                            $t('settingsAccounting.paymentAccount.add.heading')
                        }}
                    </h5>
                </div>
                <div class="flex items-center justify-center p-6">
                    <form-control-group class="w-112">
                        <form-text-input
                            v-model="bankName"
                            :error-message="bankNameError"
                            :label="
                                $t(
                                    'settingsAccounting.paymentAccount.add.bankName'
                                )
                            "
                        />

                        <form-text-input
                            v-model="name"
                            :error-message="nameError"
                            :label="
                                $t(
                                    'settingsAccounting.paymentAccount.add.accountName'
                                )
                            "
                            :placeholder="
                                $t(
                                    'settingsAccounting.paymentAccount.add.accountNamePlaceholder'
                                )
                            "
                            :hint-text="
                                $t(
                                    'settingsAccounting.paymentAccount.add.accountNameHint'
                                )
                            "
                        />

                        <form-single-select
                            v-model="accountTypeId"
                            :error-message="accountTypeIdError"
                            :options="accountTypes"
                            option-value="id"
                            option-label="name"
                            :label="
                                $t(
                                    'settingsAccounting.paymentAccount.add.accountType'
                                )
                            "
                            :placeholder="
                                $t(
                                    'settingsAccounting.paymentAccount.add.accountTypePlaceholder'
                                )
                            "
                        />
                        <template v-if="accountType">
                            <form-text-input
                                v-if="
                                    accountType.slug ===
                                    BANK_ACCOUNT_TYPE.CREDIT_CARD
                                "
                                v-model="last4"
                                :error-message="last4Error"
                                :label="
                                    $t(
                                        'settingsAccounting.paymentAccount.add.creditCardNumber'
                                    )
                                "
                                :placeholder="
                                    $t(
                                        'settingsAccounting.paymentAccount.add.creditCardNumberPlaceholder'
                                    )
                                "
                            />

                            <form-text-input
                                v-else-if="
                                    [
                                        BANK_ACCOUNT_TYPE.CASH,
                                        BANK_ACCOUNT_TYPE.BANK,
                                    ].includes(accountType.slug)
                                "
                                v-model="iban"
                                :error-message="ibanError"
                                :label="
                                    $t(
                                        'settingsAccounting.paymentAccount.add.accountNumber'
                                    )
                                "
                                :placeholder="
                                    $t(
                                        'settingsAccounting.paymentAccount.add.accountNumberPlaceholder'
                                    )
                                "
                            />
                        </template>

                        <form-single-select
                            v-model="currency"
                            :error-message="currencyError"
                            :label="$t('general.currency')"
                            :options="currencies"
                            option-value="value"
                            option-label="iso"
                            :searchable-fields="['iso', 'label']"
                        />
                    </form-control-group>
                </div>

                <div class="flex justify-between border-t border-gray-200 p-6">
                    <base-button
                        variant="default"
                        type="button"
                        @click="onCancel"
                    >
                        {{ $t('general.back') }}
                    </base-button>
                    <base-button
                        variant="primary"
                        type="submit"
                        :loading="processing"
                    >
                        {{ $t('settingsAccounting.paymentAccount.add.btnAdd') }}
                    </base-button>
                </div>
            </form>
        </div>
    </base-sticky-heading>
</template>

<script setup>
import { BANK_ACCOUNT_TYPE } from '@tenant/modules/tenant/settings-accounting/utils/constants'
import { useQuery } from '@tanstack/vue-query'

const router = useRouter()
const { t } = useI18n()

const { execute: getChartOfAccounts } = useApi(
    '/api/chart-of-accounts/slugs',
    'POST'
)

useHead({ title: t('settingsAccounting.paymentAccount.add.title') })

const { currencies, defaultCurrency } = useCurrencies()
const { handleSubmit, setErrors } = useForm()

const { execute, processing } = useApi('/api/payment-accounts', 'POST')

const { value: bankName, errorMessage: bankNameError } = useField('bank_name')
const { value: name, errorMessage: nameError } = useField('name')
const { value: accountTypeId, errorMessage: accountTypeIdError } =
    useField('account_type_id')
const { value: iban, errorMessage: ibanError } = useField('iban')
const { value: last4, errorMessage: last4Error } = useField('last4')
const {
    value: currency,
    errorMessage: currencyError,
    setValue: setCurrency,
} = useField('currency_id')

watch(
    () => defaultCurrency.value,
    (value) => {
        if (!value) {
            return
        }

        setCurrency(value.value)
    }
)

const { data: accountTypes } = useQuery({
    queryKey: ['coa-payment-account-add'],
    queryFn: () =>
        getChartOfAccounts({
            data: {
                slugs: [
                    BANK_ACCOUNT_TYPE.CREDIT_CARD,
                    BANK_ACCOUNT_TYPE.CASH,
                    BANK_ACCOUNT_TYPE.BANK,
                ],
            },
        }),
    refetchOnWindowFocus: false,
    refetchInterval: false,
})

const accountType = computed(() => {
    if (!accountTypeId.value) {
        return null
    }

    return accountTypes.value.find((i) => i.id === accountTypeId.value)
})

const onCancel = () => {
    router.push({
        name: 'settings.accounting.payment-account.connect-bank',
    })
}

const submitForm = handleSubmit((values) => {
    execute({ data: values })
        .then((paymentAccount) => {
            router.push({
                name: 'bookeeping.bank.detail',
                params: {
                    id: paymentAccount.id,
                },
            })
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
})
</script>
