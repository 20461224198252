<template>
    <div class="mb-5 flex flex-col items-center gap-2 text-center">
        <h5 class="text-lg font-medium text-gray-900">
            {{ $t('settingsAccounting.paymentAccount.integrate.fromHowFar') }}
        </h5>
        <p class="text-sm font-normal text-gray-500">
            {{ $t('settingsAccounting.paymentAccount.integrate.asPerThe') }}
        </p>
    </div>

    <div class="flex flex-col gap-3">
        <form-single-select
            v-model="date"
            :label="
                $t(
                    'settingsAccounting.paymentAccount.integrate.importStartingFrom'
                )
            "
            :options="options"
            option-label="label"
            option-value="value"
            :show-clear="false"
        />
        <form-date-picker
            v-model="value"
            v-if="date === 'custom'"
            :label="
                $t('settingsAccounting.paymentAccount.integrate.selectDate')
            "
            :single="true"
            :min-date="$filters.now().subtract('90', 'day').toDate()"
            :max-date="$filters.now().toDate()"
        />
    </div>
</template>
<script setup>
import dayjs from 'dayjs'
import { shortServerFormat } from '@tenant/utils/day'

const { t } = useI18n()

const { value, setValue } = useField('date')

const defaultSelected = shortServerFormat(dayjs().subtract(90, 'day'))

const date = ref(defaultSelected)

watch(
    () => date.value,
    (value) => {
        setValue(value === 'custom' ? null : value)
    }
)

onMounted(() => {
    setValue(date.value)
})

const options = [
    {
        value: shortServerFormat(dayjs()),
        label: t('general.today'),
    },
    {
        value: shortServerFormat(dayjs().subtract(7, 'day')),
        label: t('general.daysAgo', { day: 7 }),
    },
    {
        value: shortServerFormat(dayjs().subtract(30, 'day')),
        label: t('general.daysAgo', { day: 30 }),
    },
    {
        value: defaultSelected,
        label: t('general.daysAgo', { day: 90 }),
    },
    {
        value: 'custom',
        label: t('general.custom'),
    },
]
</script>
