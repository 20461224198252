<template>
    <base-modal
        close
        @modal-close="closeModal"
        :show="show"
        size="auto"
        class="w-[840px]"
    >
        <template #modal-header>
            <div class="flex flex-col">
                <h5 class="mb-2 text-lg font-medium text-gray-900">
                    {{
                        $t(
                            'settingsAccounting.paymentAccount.connectBank.modal.title'
                        )
                    }}
                </h5>
                <p class="text-sm font-normal text-gray-500">
                    {{
                        $t(
                            'settingsAccounting.paymentAccount.connectBank.modal.description'
                        )
                    }}
                </p>
            </div>
        </template>

        <div class="my-11 flex justify-between gap-5">
            <div class="flex flex-col items-center gap-2.5">
                <base-icon name="other:term" size="5xl" variant="inherit" />
                <p class="text-xs text-gray-500">
                    {{
                        $t(
                            'settingsAccounting.paymentAccount.connectBank.modal.steps.step1'
                        )
                    }}
                </p>
            </div>

            <div class="flex flex-col items-center gap-2.5">
                <base-icon name="other:approve" size="5xl" variant="inherit" />
                <p class="text-xs text-gray-500">
                    {{
                        $t(
                            'settingsAccounting.paymentAccount.connectBank.modal.steps.step2'
                        )
                    }}
                </p>
            </div>

            <div class="flex flex-col items-center gap-2.5">
                <base-icon name="other:bank" size="5xl" variant="inherit" />
                <p class="text-xs text-gray-500">
                    {{
                        $t(
                            'settingsAccounting.paymentAccount.connectBank.modal.steps.step3'
                        )
                    }}
                </p>
            </div>

            <div class="flex flex-col items-center gap-2.5">
                <base-icon name="other:accept" size="5xl" variant="inherit" />
                <p class="text-xs text-gray-500">
                    {{
                        $t(
                            'settingsAccounting.paymentAccount.connectBank.modal.steps.step4'
                        )
                    }}
                </p>
            </div>
        </div>

        <template #modal-footer-outer>
            <footer class="border-t p-6 pt-8">
                <div class="flex items-center justify-between gap-3">
                    <div class="flex flex-1">
                        <form-checkbox
                            v-model="isAgreed"
                            :supporting-text="
                                $t(
                                    'settingsAccounting.paymentAccount.connectBank.modal.agreeSubText'
                                )
                            "
                        >
                            <template #text>
                                <p
                                    class="text-sm text-gray-700"
                                    v-html="
                                        $t(
                                            'settingsAccounting.paymentAccount.connectBank.modal.agreeText'
                                        )
                                    "
                                ></p>
                            </template>
                        </form-checkbox>
                    </div>

                    <base-button outline variant="default" @click="closeModal">
                        {{ $t('general.cancel') }}
                    </base-button>

                    <base-button @click="onSubmit" :disabled="!isAgreed">
                        {{ $t('bookeepingBank.connectNow') }}
                    </base-button>
                </div>
            </footer>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'on-submit'])

defineProps({
    show: {
        type: Boolean,
        default: true,
    },
})

const closeModal = () => {
    emit('modal-close')
}

const onSubmit = () => {
    emit('on-submit')
}

const isAgreed = ref(false)
</script>
