export default {
    settingsAccounting: {
        sequence: {
            list: {
                title: 'Sequence',
                emptyText: 'No sequence found',
                emptySubText: 'Create your first sequence',
                formatColumn: 'Format',
                typeColumn: 'Type',
                nextNumberColumn: 'Next number',
                nextSequenceColumn: 'Next sequence',
            },
            edit: {
                title: 'Edit Sequence Setting',
                formatLabel: 'Format',
                formatDescription: 'The following variables are available:',
                nextDigitLabel: '{format} - Next digit',
                currentYearLabel: '{format} - Current year ({digit})',
                currentMonthLabel: '{format} - Current month ({digit})',
                currentDayLabel: '{format} - Current day ({digit})',
                nextNumberLabel: 'Next number',
                previewLabel: 'Preview',
            },
            tooltip: {
                sequenceDeleteDisableLabel: 'A sequence can’t be deleted',
            },
            message: {
                editSuccess: 'Save sequence success',
            },
        },
        paymentAccount: {
            connectBank: {
                title: 'Add Bank Accounts',
                findAccount: 'Find account',
                findDescription:
                    'Search for a bank, credit card or payment provider to connect to Cybooks',
                addWithoutBankFeed: 'Add without bank feed',
                searchText: 'Search by bank name or BIC',
                searching: 'searching ...',
                noResultsMatch: 'No results match your search',
                tryCheckingYourSpelling:
                    "Try checking your spelling. If you can't find your bank, add an account without a bank feed to manually import transactions into Cybooks.",
                bankDescription: 'BIC: {bic}',
                modal: {
                    title: 'Steps to Connect to GoCardless',
                    description:
                        'Connect your bank account to our alternate service provider in four simple steps as mentioned above. In cases where the banks do not use two-factor authentication to verify your accounts, Step 3 will not be applicable. You can directly proceed to Step 4 and provide permissions to token.',
                    agreeText:
                        'I agree to GoCardless’s <a class="text-primary-600" target="_blank" href="https://gocardless.com/legal/bank-account-data/">end user terms of service</a>',
                    agreeSubText:
                        'Cybooks is a PSD2 Agent of GoCardless Ltd., which is registered in England and Wales and is authorised by the Financial Conduct Authority. The FCA reference number of GoCardless is 597190.',
                    steps: {
                        step1: 'Accept the Terms of GoCardless',
                        step2: 'Login to Your Account',
                        step3: 'Verify your Bank Account',
                        step4: 'Provide Permissions to GoCardless',
                    },
                },
                cannotConnectMsg:
                    'Cannot connect to bank at the moment. Please try again later.',
            },
            addModal: {
                title: 'Add an account without a bank feed',
                youMayWant: 'You may want to do this if:',
                li1: "Your bank doesn't currently provide a bank feed",
                li2: "Your bank doesn't provide a bank feed for your account type",
                li3: 'You need a petty cash account',
                transactionDownload:
                    'Transactions downloaded from your bank can be manually imported into this account',
            },
            add: {
                title: 'Add Account',
                heading: 'Account details',
                bankName: 'Bank name',
                accountName: 'Account name',
                accountNamePlaceholder: 'e.g business account',
                accountNameHint: 'A unique name for this account',
                accountType: 'Account type',
                accountTypePlaceholder: 'Select account type',
                creditCardNumber: 'Credit card number',
                creditCardNumberPlaceholder: 'Last 4 digits:',
                accountNumber: 'Account number',
                accountNumberPlaceholder: 'e.g CY-12-41239-1231',
                btnAdd: 'Add Account',
            },
            integrate: {
                title: 'Integrate your bank',
                connectSuccessHeading:
                    'Your {name} account was successfully connected.',
                connectSuccessDescription:
                    'You can now either choose to go to your Payment accounts page, or to connect another bank.',
                connectOtherButton: 'Connect another Bank',
                goToAccountButton: 'Go to your Accounts page',
                finalizeBankConnection: 'Finalize bank connection',
                selectTheBankAccount:
                    'Select the bank account that you want to connect',
                fromHowFar:
                    'From how far back would you like to import your transactions?',
                asPerThe:
                    'As per the PSD2 regulations, you can fetch bank feeds only for the past 90 days.',
                importStartingFrom: 'Import starting from',
                selectDate: 'Select date',
                nameYourBank: 'Name your bank',
                enterAName:
                    'Enter a name to display the bank in Cybooks. You can change this name later if you want to.',
                finishAndConnect: 'Finish & connect',
                connectingYourBank: 'Connecting to your bank ...',
                thisCanTake:
                    'This can take a few minutes. The screen will close automatically or you can close it by yourself',
                closeAndGoToBack: 'Close and go to bank overview',
            },
            fields: {
                nameLabel: 'Name *',
                namePlaceholder: 'Enter your account name',
                ibanLabel: 'IBAN',
                ibanPlaceholder: 'Enter your account IBAN',
                chartOfAccountLabel: 'Chart of account',
            },
            message: {
                createSuccess: 'Create payment account success',
                editSuccess: 'Save payment account success',
                codeExpired: 'The authorization code has expired.',
            },
        },
    },
}
