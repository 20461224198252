<template>
    <div class="mb-5 flex flex-col items-center gap-2 text-center">
        <h5 class="text-lg font-medium text-gray-900">
            {{
                $t(
                    'settingsAccounting.paymentAccount.integrate.finalizeBankConnection'
                )
            }}
        </h5>
        <p class="text-sm font-normal text-gray-500">
            {{
                $t(
                    'settingsAccounting.paymentAccount.integrate.selectTheBankAccount'
                )
            }}
        </p>
    </div>

    <div class="flex flex-col gap-y-5">
        <form-radio-group-item
            name="account"
            v-for="account in accounts"
            :value="account.identifier"
            v-model="value"
            :key="account.identifier"
        >
            <template #default="{ checked }">
                <div class="flex w-full flex-col justify-between pr-10">
                    <p class="mb-0.5 block text-md font-medium text-gray-700">
                        <span class="mr-2">
                            {{ account.label }}
                            -
                            {{ account.accountName }}
                        </span>
                        <span>*{{ account.last4Digits }}</span>
                    </p>
                    <p
                        class="block text-md font-normal"
                        :class="checked ? 'text-primary-600' : 'text-gray-500'"
                        v-if="account.currency"
                    >
                        {{
                            $t('general.balance', {
                                balance: $filters.currency(
                                    account.balance,
                                    account.currency.iso
                                ),
                            })
                        }}
                    </p>
                </div>
            </template>
        </form-radio-group-item>
    </div>
</template>
<script setup>
defineProps({
    accounts: {
        type: Array,
        default: () => [],
    },
})

const { value } = useField('account_identifier')
</script>
