<template>
    <div class="mb-5 flex flex-col items-center gap-2 text-center">
        <h5 class="text-lg font-medium text-gray-900">
            {{ $t('settingsAccounting.paymentAccount.integrate.nameYourBank') }}
        </h5>
        <p class="text-sm font-normal text-gray-500">
            {{ $t('settingsAccounting.paymentAccount.integrate.enterAName') }}
        </p>
    </div>
    <form-text-input
        ref="input"
        v-model="value"
        :placeholder="
            $t('settingsAccounting.paymentAccount.integrate.nameYourBank')
        "
    />
</template>
<script setup>
const props = defineProps({
    accounts: {
        type: Array,
        default: () => [],
    },
})

const input = ref(null)
const { value, setValue } = useField('bank_name')

const accountIdentify = useFieldValue('account_identifier')

const selectedAccount = computed(() =>
    props.accounts.find((i) => i.identifier === accountIdentify.value)
)

onMounted(() => {
    setValue(selectedAccount.value.label)

    input.value && input.value.focus()
})
</script>
