<template>
    <base-modal
        :show="show"
        :show-header="false"
        :show-footer="false"
        :loading="loadingAccounts"
        size="md"
    >
        <div
            v-if="processingIntegrate"
            class="flex min-h-96 flex-col items-center justify-center gap-4"
        >
            <base-loading size="xl" />
            <p class="text-lg font-medium text-gray-700">
                {{
                    $t(
                        'settingsAccounting.paymentAccount.integrate.connectingYourBank'
                    )
                }}
            </p>
        </div>
        <template v-else>
            <div class="flex min-h-72 flex-col pb-5">
                <payment-account-integrate-account-modal-accounts
                    v-if="state.step === 'accounts'"
                    :accounts="accounts"
                />
                <payment-account-integrate-account-modal-date
                    v-else-if="state.step === 'date'"
                />
                <payment-account-integrate-account-modal-naming
                    v-else-if="state.step === 'naming'"
                    :accounts="accounts"
                />
            </div>
            <div
                v-if="steps.length > 0"
                class="flex flex-col items-center gap-8"
            >
                <div class="flex gap-2">
                    <div
                        v-for="step in steps"
                        :key="step"
                        class="h-1.5 w-11 rounded-full transition-all duration-200"
                        :class="
                            step === state.step
                                ? 'bg-primary-700'
                                : 'bg-gray-100'
                        "
                    />
                </div>

                <div class="flex w-full gap-3">
                    <base-button
                        v-if="steps.indexOf(state.step) > 0"
                        variant="default"
                        full-width
                        @click="previousStep"
                    >
                        {{ $t('general.back') }}
                    </base-button>
                    <base-button
                        v-else
                        variant="default"
                        full-width
                        @click="onCancel"
                    >
                        {{ $t('general.cancel') }}
                    </base-button>

                    <base-button
                        v-if="steps.indexOf(state.step) === steps.length - 1"
                        variant="primary"
                        :disabled="ableToNext"
                        full-width
                        @click="onSubmit"
                    >
                        {{
                            $t(
                                'settingsAccounting.paymentAccount.integrate.finishAndConnect'
                            )
                        }}
                    </base-button>
                    <base-button
                        v-else
                        variant="primary"
                        :disabled="ableToNext"
                        full-width
                        @click="nextStep"
                    >
                        {{ $t('general.next') }}
                    </base-button>
                </div>
            </div>
        </template>
    </base-modal>
</template>
<script setup>
import { ref } from 'vue'
import { usePaymentAccount } from '@tenant/composables/apis/use-payment-accounts'
const emit = defineEmits(['on-success'])

defineProps({
    show: {
        type: Boolean,
        required: true,
    },
})

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { errorNotify } = useNotification()

const steps = ref([])

const state = reactive({
    step: null,
})

const ableToNext = computed(() => {
    const { account_identifier, date, bank_name } = values
    if (state.step === 'accounts') {
        return !account_identifier
    }

    if (state.step === 'date') {
        return !date
    }

    if (state.step === 'naming') {
        return !bank_name
    }

    return false
})

const { handleSubmit, setValues, values } = useForm({
    keepValuesOnUnmount: true,
})

const {
    execute: executeAccounts,
    result: accounts,
    loading: loadingAccounts,
} = useApi('/api/payment-accounts/bank/accounts', 'POST')

const { execute: executeAccountIntegrate, processing: processingIntegrate } =
    useApi('/api/payment-accounts/bank/accounts/integrate', 'POST')

onMounted(() => {
    getAccounts()
})

const getAccounts = async () => {
    try {
        const { code, ref, ...queries } = route.query

        accounts.value = await executeAccounts({
            data: {
                ...queries,
                code: code || ref,
            },
        })

        configSteps()
    } catch {
        errorNotify({
            title: t('settingsAccounting.paymentAccount.message.codeExpired'),
        })

        onCancel()
    }
}

const nextStep = () => {
    let idx = steps.value.indexOf(state.step)
    if (idx > -1 && idx < steps.value.length - 1) {
        idx++
    }

    state.step = steps.value[idx]
}

const previousStep = () => {
    let idx = steps.value.indexOf(state.step)
    if (idx > 0) {
        idx--
    }

    state.step = steps.value[idx]
}

const configSteps = () => {
    if (!accounts.value) {
        return
    }

    if (accounts.value.length === 1) {
        steps.value = ['date', 'naming']
        state.step = 'date'
        setValues({
            account_identifier: accounts.value[0].identifier,
        })

        return
    }

    steps.value = ['accounts', 'date', 'naming']
    state.step = 'accounts'
}

const onCancel = () => {
    router.push({
        name: 'settings.accounting.payment-account.connect-bank',
    })
}

const { invalidatePaymentAccount } = usePaymentAccount()
const onSubmit = handleSubmit(async (values) => {
    try {
        const { code, ref, ...queries } = route.query
        const response = await executeAccountIntegrate({
            data: {
                ...queries,
                ...values,
                code: code || ref,
            },
        })

        invalidatePaymentAccount()
        emit('on-success', response)
    } catch {
        errorNotify({
            title: t('settingsAccounting.paymentAccount.message.codeExpired'),
        })

        onCancel()
    }
})
</script>
